*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #f4f6f8;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

html {
  font-family: Inter, sans-serif;
}

.display1 {
  font-family: DM Sans, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

.title5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.body2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.body4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.body5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.body8 {
  letter-spacing: -0.5%;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.code2 {
  font-family: DM Mono, monospace;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.md-list {
  --tw-text-opacity: 1;
  color: rgb(82 86 88 / var(--tw-text-opacity, 1));
  padding-left: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  list-style-type: disc;
}

.md-listitem {
  margin-top: 12px;
  margin-bottom: 12px;
}

.md-p {
  --tw-text-opacity: 1;
  color: rgb(82 86 88 / var(--tw-text-opacity, 1));
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.md-codespan {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(232 235 239 / var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgb(244 246 248 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(82 86 88 / var(--tw-text-opacity, 1));
  border-radius: 6px;
  padding: 2px 8px;
  font-family: DM Mono, monospace;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
}

.md-code-wrapper {
  margin-top: 12px;
  margin-bottom: 22px;
}

.md-code {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  font-family: DM Mono, monospace;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(244 246 248 / var(--tw-bg-opacity, 1)) !important;
}

.md-code-no-filename {
  border-radius: 8px;
  font-family: DM Mono, monospace;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(244 246 248 / var(--tw-bg-opacity, 1)) !important;
}

.md-heading1 {
  --tw-text-opacity: 1;
  color: rgb(29 29 29 / var(--tw-text-opacity, 1));
  margin-top: 28px;
  margin-bottom: 28px;
  font-family: DM Sans, sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 2.25rem;
}

.md-heading2 {
  --tw-text-opacity: 1;
  color: rgb(29 29 29 / var(--tw-text-opacity, 1));
  margin-top: 1.5rem;
  margin-bottom: .75rem;
  font-family: DM Sans, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

.md-heading4 {
  margin-top: .5rem;
  margin-bottom: .5rem;
  scroll-margin-top: 64px;
  display: block;
}

.md-wrapper {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 1.5rem;
}

@media (width >= 768px) {
  .md-wrapper {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.md-code-filename {
  --tw-bg-opacity: 1;
  background-color: rgb(232 235 239 / var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(82 86 88 / var(--tw-text-opacity, 1));
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px 20px;
  font-family: DM Mono, monospace;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
}

.md-code-incorrect {
  --tw-border-opacity: 1;
  border-left-width: 3px;
  border-left-color: rgb(233 76 107 / var(--tw-border-opacity, 1));
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-left: 7px;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(244 246 248 / var(--tw-bg-opacity, 1)) !important;
}

.md-code-correct {
  --tw-border-opacity: 1;
  border-left-width: 3px;
  border-left-color: rgb(94 186 148 / var(--tw-border-opacity, 1));
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-left: 7px;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(244 246 248 / var(--tw-bg-opacity, 1)) !important;
}

h1, h2, h3, h4 {
  scroll-margin-top: 76px;
}

.md-a {
  --tw-text-opacity: 1;
  color: rgb(103 80 210 / var(--tw-text-opacity, 1));
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.md-a:hover {
  text-decoration-line: underline;
}

.md-table-wrapper {
  overflow-x: auto;
}

.md-table {
  border-collapse: collapse;
  min-width: 700px;
}

@media (width >= 768px) {
  .md-table {
    width: 100%;
  }
}

.md-th {
  --tw-bg-opacity: 1;
  background-color: rgb(244 246 248 / var(--tw-bg-opacity, 1));
  text-align: left;
  --tw-text-opacity: 1;
  color: rgb(29 29 29 / var(--tw-text-opacity, 1));
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.md-td {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(232 235 239 / var(--tw-border-opacity, 1));
  --tw-text-opacity: 1;
  color: rgb(82 86 88 / var(--tw-text-opacity, 1));
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.left-\[0px\] {
  left: 0;
}

.top-0 {
  top: 0;
}

.top-\[67px\] {
  top: 67px;
}

.z-50 {
  z-index: 50;
}

.m-auto {
  margin: auto;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-\[8px\] {
  margin-left: 8px;
  margin-right: 8px;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-\[24px\] {
  margin-bottom: 24px;
}

.ml-0 {
  margin-left: 0;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-\[12px\] {
  margin-right: 12px;
}

.mr-\[9px\] {
  margin-right: 9px;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-\[12px\] {
  margin-top: 12px;
}

.mt-\[17px\] {
  margin-top: 17px;
}

.mt-\[22px\] {
  margin-top: 22px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-\[150px\] {
  height: 150px;
}

.h-\[21px\] {
  height: 21px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[28px\] {
  height: 28px;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[66px\] {
  height: 66px;
}

.h-full {
  height: 100%;
}

.max-h-0 {
  max-height: 0;
}

.min-h-\[300px\] {
  min-height: 300px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[21px\] {
  width: 21px;
}

.w-\[24px\] {
  width: 24px;
}

.w-\[26px\] {
  width: 26px;
}

.w-\[28px\] {
  width: 28px;
}

.w-full {
  width: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-\[12px\] {
  gap: 12px;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[12px\] {
  border-radius: 12px;
}

.rounded-\[6px\] {
  border-radius: 6px;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.border, .border-\[1px\] {
  border-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.\!border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(244 246 248 / var(--tw-border-opacity, 1)) !important;
}

.\!border-slate-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(30 41 59 / var(--tw-border-opacity, 1)) !important;
}

.border-accent {
  --tw-border-opacity: 1;
  border-color: rgb(103 80 210 / var(--tw-border-opacity, 1));
}

.border-black-500 {
  --tw-border-opacity: 1;
  border-color: rgb(110 110 110 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(232 235 239 / var(--tw-border-opacity, 1));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.bg-\[\#f6f6f7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 247 / var(--tw-bg-opacity, 1));
}

.bg-accent {
  --tw-bg-opacity: 1;
  background-color: rgb(103 80 210 / var(--tw-bg-opacity, 1));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 246 248 / var(--tw-bg-opacity, 1));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 235 239 / var(--tw-bg-opacity, 1));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-\[12px\] {
  padding: 12px;
}

.p-\[6px\] {
  padding: 6px;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}

.px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}

.px-\[1px\] {
  padding-left: 1px;
  padding-right: 1px;
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.px-\[24px\] {
  padding-left: 24px;
  padding-right: 24px;
}

.px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[12px\] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px;
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pb-\[54px\] {
  padding-bottom: 54px;
}

.pb-\[80px\] {
  padding-bottom: 80px;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-\[24px\] {
  padding-top: 24px;
}

.pt-\[32px\] {
  padding-top: 32px;
}

.pt-\[66px\] {
  padding-top: 66px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-\[800\] {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.lowercase {
  text-transform: lowercase;
}

.\!text-slate-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(15 23 42 / var(--tw-text-opacity, 1)) !important;
}

.text-accent {
  --tw-text-opacity: 1;
  color: rgb(103 80 210 / var(--tw-text-opacity, 1));
}

.text-black-500 {
  --tw-text-opacity: 1;
  color: rgb(110 110 110 / var(--tw-text-opacity, 1));
}

.text-black-700 {
  --tw-text-opacity: 1;
  color: rgb(82 86 88 / var(--tw-text-opacity, 1));
}

.text-black-900 {
  --tw-text-opacity: 1;
  color: rgb(29 29 29 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.no-underline {
  text-decoration-line: none;
}

.shadow-\[0_-1px_0_0_\#E8EBEF\] {
  --tw-shadow: 0 -1px 0 0 #e8ebef;
  --tw-shadow-colored: 0 -1px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.drop-shadow-\[0_1px_\#E8EBEF\] {
  --tw-drop-shadow: drop-shadow(0 1px #e8ebef);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-\[4px_0_2px_\#E8EBEF\] {
  --tw-drop-shadow: drop-shadow(4px 0 2px #e8ebef);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[max-height\] {
  transition-property: max-height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

@media (width >= 768px) {
  .md\:title5 {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }

  .md\:body5 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-slate-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity, 1));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 246 248 / var(--tw-bg-opacity, 1));
}

.hover\:text-black-900:hover {
  --tw-text-opacity: 1;
  color: rgb(29 29 29 / var(--tw-text-opacity, 1));
}

.peer:checked ~ .peer-checked\:max-h-\[200px\] {
  max-height: 200px;
}

@media (width >= 768px) {
  .md\:fixed {
    position: fixed;
  }

  .md\:relative {
    position: relative;
  }

  .md\:left-0 {
    left: 0;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:top-auto {
    top: auto;
  }

  .md\:mb-\[50px\] {
    margin-bottom: 50px;
  }

  .md\:ml-\[280px\] {
    margin-left: 280px;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:mt-\[20px\] {
    margin-top: 20px;
  }

  .md\:mt-\[66px\] {
    margin-top: 66px;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[calc\(100vh-66px\)\] {
    height: calc(100vh - 66px);
  }

  .md\:max-h-max {
    max-height: max-content;
  }

  .md\:w-\[200px\] {
    width: 200px;
  }

  .md\:w-\[280px\] {
    width: 280px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:min-w-\[400px\] {
    min-width: 400px;
  }

  .md\:max-w-\[1200px\] {
    max-width: 1200px;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-\[20px\] {
    gap: 20px;
  }

  .md\:gap-\[24px\] {
    gap: 24px;
  }

  .md\:overflow-y-auto {
    overflow-y: auto;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:pb-\[60px\] {
    padding-bottom: 60px;
  }

  .md\:pb-\[80px\] {
    padding-bottom: 80px;
  }

  .md\:pl-\[280px\] {
    padding-left: 280px;
  }

  .md\:pt-\[60px\] {
    padding-top: 60px;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }

  .group:hover .md\:group-hover\:inline {
    display: inline;
  }

  .peer:checked ~ .md\:peer-checked\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:bg-slate-900\/80 {
    background-color: #0f172acc;
  }
}

.\[\&\>div\]\:h-\[2px\] > div {
  height: 2px;
}

.\[\&\>div\]\:w-\[18px\] > div {
  width: 18px;
}

.\[\&\>div\]\:bg-black-900 > div {
  --tw-bg-opacity: 1;
  background-color: rgb(29 29 29 / var(--tw-bg-opacity, 1));
}

.\[\&\>li\>a\]\:px-\[16px\] > li > a {
  padding-left: 16px;
  padding-right: 16px;
}

.\[\&\>li\>a\]\:py-\[12px\] > li > a {
  padding-top: 12px;
  padding-bottom: 12px;
}

@media (width >= 768px) {
  .md\:\[\&\>li\>a\]\:px-0 > li > a {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:\[\&\>li\>a\]\:py-0 > li > a {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.\[\&\>li\]\:mb-\[12px\] > li {
  margin-bottom: 12px;
}

.\[\&\>li\]\:inline-block > li {
  display: inline-block;
}

.\[\&\>li\]\:rounded-\[10px\] > li {
  border-radius: 10px;
}

.\[\&\>li\]\:rounded-t > li {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.\[\&\>li\]\:border-\[1px\] > li {
  border-width: 1px;
}

.\[\&\>li\]\:border-b-0 > li {
  border-bottom-width: 0;
}

.\[\&\>li\]\:border-l > li {
  border-left-width: 1px;
}

.\[\&\>li\]\:border-slate-300 > li {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}

.\[\&\>li\]\:border-transparent > li {
  border-color: #0000;
}

.\[\&\>li\]\:p-2 > li {
  padding: .5rem;
}

.\[\&\>li\]\:px-\[12px\] > li {
  padding-left: 12px;
  padding-right: 12px;
}

.\[\&\>li\]\:py-1 > li {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.\[\&\>li\]\:py-\[4px\] > li {
  padding-top: 4px;
  padding-bottom: 4px;
}

.\[\&\>li\]\:pl-3 > li {
  padding-left: .75rem;
}
/*# sourceMappingURL=index.78a7bf27.css.map */
